<template>
  <div id="projects">
    <vs-progress class="w-full" v-if="!loaded" indeterminate color="primary"></vs-progress>
    <div v-else>
      <vs-table :data="projects" pagination :max-items="itemsPerPage" search>
        <template slot="thead">
          <vs-th sort-key="project_id">{{ $t('project_id') }}</vs-th>
          <vs-th sort-key="project_slug">{{ $t('project_slug') }}</vs-th>
          <vs-th sort-key="project_nicename">{{ $t('project_nicename') }}</vs-th>
          <vs-th sort-key="users">{{ $t('users') }}</vs-th>
          <vs-th sort-key="layers">{{ $t('layers') }}</vs-th>
          <vs-th sort-key="portal">{{ $t('portal') }}</vs-th>
          <vs-th sort-key="layers_total_size">{{ $t('layers_total_size') }}</vs-th>
          <vs-th sort-key="layers_types">{{ $t('layers_types') }}</vs-th>
          <vs-th>{{ $t('actions') }}</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data" :state="projectStatusColor(data[indextr])">
            <!-- project_id -->
            <vs-td :data="data[indextr].project_id">
              <span>#{{data[indextr].project_id}}</span>
            </vs-td>
            <!-- project_slug -->
            <vs-td :data="data[indextr].project_slug">
              <span><a :href="`/project/${data[indextr].project_slug}`" target="_blank" rel="nofollow">{{ data[indextr].project_slug }}</a></span>
            </vs-td>
            <!-- project_nicename -->
            <vs-td :data="data[indextr].project_nicename">
              <span>{{ decodeURIComponent(data[indextr].project_nicename) }}</span>
            </vs-td>
            <!-- users -->
            <vs-td :data="data[indextr].users">
              <span>{{ data[indextr].users }}</span>
            </vs-td>
            <!-- layers -->
            <vs-td :data="data[indextr].layers">
              <span>{{ data[indextr].layers }}</span>
            </vs-td>
            <!-- portal -->
            <vs-td :data="data[indextr].portal">
              <span>{{ data[indextr].portal }}</span>
            </vs-td>
            <!-- layers_total_size -->
            <vs-td :data="data[indextr].layers_total_size">
              <span v-if="data[indextr].layers > 0">{{ parseFloat(data[indextr].layers_total_size) | prettyBytes }}</span>
            </vs-td>
            <!-- layers_types -->
            <vs-td :data="data[indextr].layers_types">
              <span>{{ data[indextr].layers_types }}</span>
            </vs-td>
            <!-- actions -->
            <vs-td :data="data[indextr].layer_name">
              <a :href="`/project/${data[indextr].project_slug}`" target="_blank" rel="nofollow">{{ $t("edit") }}</a>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
  </div>
</template>
<script>
import axios from '@/axios.js'
import vSelect from 'vue-select'
import moment from 'moment'
import VueApexCharts from 'vue-apexcharts'
import apexChatData from 'apexcharts'

import Datepicker from 'vuejs-datepicker'

export default {
  components: {
    moment,
    VueApexCharts, 
    vSelect, 
    Datepicker
  },
  data () {
    return {
      apexChatData,
      api_server_baseurl: this.$appConfig.apiBaseUrl,
      appConfig: this.$appConfig,
      projects: {},
      itemsPerPage: 50,
      loaded: false
    }
  },
  methods: {   
    refreshAll () {
      this.getProjectsInfos()
    },
    getProjectsInfos () {
      const params = ''
      const rimnat_api_call_url = `${this.api_server_baseurl}/admin/project/getProjectsSynthesis?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          const projects = response.data
          for (let i = 0; i < projects.length; i++) {
            // convert string to int to allow sort in table
            try {
              projects[i].layers_total_size = parseInt(projects[i].layers_total_size)
              projects[i].layers = parseInt(projects[i].layers)
              projects[i].users = parseInt(projects[i].users)
            } catch (error) {
              console.error(error)
            }
          }
          this.projects = projects
          this.loaded = true
        })
        .catch((error)   => { console.log(error) })
    },
    getSizeColor (num) {
      if (num > 80)  return 'danger'
      if (num > 60)  return 'warning'
      if (num >= 40) return 'primary'
      if (num < 40)  return 'success'
      return 'primary'
    },
    projectStatusColor (project) {
      let color = ''
      if (project.layers == 0) color = 'danger'
      if (project.portal == '') color = 'danger'
      return color
    }
  },
 
  computed: {

  },

  beforeMount () {
  },

  mounted () {
    document.body.classList.add('theme-dark')
    this.refreshAll()
  },

  watch: {
    scale () {
      this.refreshAll()
    },
    start () {
      this.refreshAll()
    },
    end () {
      this.refreshAll()
    }
  }
}

</script>
<style lang="scss">

.theme-dark input {
    background: none !important;
}
</style>
